import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const SelectWithSearch = ({ options, onSelect,zonaActual }) => {
  return (
    <Autocomplete
      options={options}
      onChange={(event, value) => onSelect(value)}
      renderInput={(params) => (
        <TextField {...params} label={options.nom_zona_est} variant="outlined" />
      )}
    />
  );
};

export default SelectWithSearch;