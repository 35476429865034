import React from 'react';
import "../../assets/styled/Modal.css";

const ModalAproved = ({ message, isVisible }) => {
    if (!isVisible) return null;
  return (
    <div className="modal-overlay">
      <div className="modal">
        <button>&times;</button>
        <p>{message}</p>
      </div>
    </div>
  )
}

export default ModalAproved
