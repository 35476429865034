import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme, Button } from "@mui/material";
import axios from "axios";
import { API_URL } from "../../auth/constants";
import React, { useEffect, useState } from "react";
import ModalDetUser from "../modals/ModalDetUser";

const TableUsuarios = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usuarios, setUsuarios] = useState([]);
  const [estudiantes, setEstudiantes] = useState([]);

  const handleEdit = (data) => {
    //console.log("handleEdit", data);
    setModalData(data);
    //console.log("modalData", modalData);
    setIsModalOpen(true);
  };

  const actualizarEstudiante = (index, nuevoEstudiante) => {
    //console.log("quellega:", index, "--", nuevoEstudiante);
    //console.log("actualizarEstudiante1",usuarios)
  
    const nuevosEstudiantes = usuarios.map(item => {
      // Comparar el id_usuario de cada item con el id_usuario del nuevoEstudiante
      if (item.id_usuario === nuevoEstudiante.id_usuario) {
        // Si son iguales, retornar el nuevoEstudiante para reemplazar el antiguo
        //console.log("entro",item.id_usuario)
        return {
          ...item,
          ...nuevoEstudiante
        };
      }
      // Si no son iguales, retornar el item sin cambios
      return item;
    });
  
    // Actualizar el estado estudiantes
    setUsuarios(nuevosEstudiantes);
    //console.log("actualizarEstudiante2",usuarios)
    //console.log("estudiantes actualizados:::", usuarios);
    //console.log("estudiantes estado:::", nuevosEstudiantes);
  };

  const handleDelete = async (data) => {
    //console.log("handleDeleteUser", data.id_usuario);
    try {
      await axios.delete(`${API_URL}/user/${data.id_usuario}`);
      // Eliminar la fila de estudiantes en el estado
      setUsuarios((prevEstudiantes) =>
        prevEstudiantes.filter(
          (usuarios) => usuarios.id_usuario !== data.id_usuario
        )
      );
      //console.log("Estudiante eliminado:", data.id_usuario);
    } catch (error) {
      console.error("Error al eliminar estudiante:", error);
    }
  };

  //modal

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const openModal = (data) => {
    setModalData(data);
    //console.log("openModal", modalData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  //
  const fetchEstudiantes = async () => {
    try {
      const response = await axios.get(`${API_URL}/user`);
      setUsuarios(response.data.students);
      //console.log("fetchEstudiantes", usuarios, "---", response.data.students);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  useEffect(() => {
    fetchEstudiantes();
  }, []);

  const columns = [
    { field: "usuario", headerName: "Usuario", flex: 0.5 },
    { field: "nom_usu", headerName: "Nombre", flex: 1 },
    { field: "ape1_usu", headerName: "Apellido Paterno", flex: 1 },
    { field: "ape2_usu", headerName: "Apellido Materno", flex: 1 },
    {
      field: "opciones",
      headerName: "Opciones",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleEdit(params.row);
              //openModal(params.row); // Pasar los datos del estudiante al modal
            }}
          >
            Editar
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row)}
          >
            Eliminar
          </Button>
        </Box>
      ),
    },
  ];
  return (
    <>
      <Box m="20px">
        <Header
          title="Informacion de Usuario"
          subtitle="Historial Usuarios"
        />
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={usuarios}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            getRowId={(row) => row.id_usuario}
          />
        </Box>
      </Box>
      <ModalDetUser
        isOpen={isModalOpen}
        onClose={closeModal}
        modalData={modalData}
        estudiantes={estudiantes} // Pasa el estado de estudiantes como prop
        setEstudiantes={setEstudiantes} // Pasa la función para actualizar estudiantes como prop
        actualizarEstudiante={actualizarEstudiante} // Pasa la función para actualizar un estudiante específico como prop

      >
        <button onClick={closeModal}>Close Modal</button>
      </ModalDetUser>
    </>
  );
};

export default TableUsuarios