import React from 'react';
import CSVReader from 'react-csv-reader';
import { API_URL } from '../../auth/constants';
import axios from 'axios';

const Import = () => {
  const handleFormSubmit = (values) => {
    console.log("Valores del formulario:", values);
    console.log(`${API_URL}/importdata`);
    axios
      .post(`${API_URL}/importdata`, values)
      .then((response) => {
        console.log("registrado",response);
      })
      .catch((error) => {
        console.error("Error al enviar los datos:", error);
      });
  };

  const handleFile = (data) => {
    console.log("Datos sin procesar:", data);

    if (data.length === 0) {
      console.log("¡El arreglo de datos está vacío!");
      return;
    }
    // Filtrar datos vacíos
    const cleanData = data.filter(item => item.length > 1);
    console.log("Datos filtrados:", cleanData);

    console.log(`${API_URL}/importdata`);
    axios
      .post(`${API_URL}/importdata`, cleanData)
      .then((response) => {
        console.log("registrado",response);
      })
      .catch((error) => {
        console.error("Error al enviar los datos:", error);
      });
    
    // Aquí puedes procesar los datos del CSV
    // Por ejemplo, puedes enviarlos a un servidor para su procesamiento
    // o trabajar con ellos localmente
  };
  const handleFileInstituciones = (data) => {
    console.log("docentesantes:",data)
    
    const cleanData = data.filter(item => item.length > 0);
    console.log("docentes:",cleanData)


    axios
      .post(`${API_URL}/importdata/datainstituciones`, cleanData)
      .then((response) => {
        console.log("registrado",response);
      })
      .catch((error) => {
        console.error("Error al enviar los datos:", error);
      });


  }
  const handleFileDocentes = (data) => {
    console.log("docentesantes:",data)
    
    const cleanData = data.filter(item => item.length > 1);
    console.log("docentes:",cleanData)


    axios
      .post(`${API_URL}/importdata/datadocente`, cleanData)
      .then((response) => {
        console.log("registrado",response);
      })
      .catch((error) => {
        console.error("Error al enviar los datos:", error);
      });


  }

  const handleFileDetalleDocentes = (data) => {
    console.log("handleFileDetalleDocentes:",data)
    
    const cleanData = data.filter(item => item.length > 1);
    console.log("handleFileDetalleDocentes:",cleanData)


    axios
      .post(`${API_URL}/importdata/datadetdoc`, cleanData)
      .then((response) => {
        console.log("registrado",response);
      })
      .catch((error) => {
        console.error("Error al enviar los datos:", error);
      });


  }

  return (
    <div>
      detalle_est
      <CSVReader onFileLoaded={handleFile} />
      institutos
      <CSVReader onFileLoaded={handleFileInstituciones} />
      docentes
      <CSVReader onFileLoaded={handleFileDocentes} />
      detalle_docentes
      <CSVReader onFileLoaded={handleFileDetalleDocentes} />

    </div>
  );
};

export default Import;
