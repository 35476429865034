import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthProvider";
import { API_URL } from "../../auth/constants";

const Signup = () => {
  const [name, setName] = useState("");
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorResponse, setErrorResponse] = useState("");
  const auth = useAuth();
  const goTo = useNavigate();

  if (auth.isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }
  async function hanleSubmit(e) {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          userName,
          password,
        }),
      });
      console.log(name, "-", userName, "-", password);
      if (response.ok) {
        console.log("User created successfully");
        setErrorResponse("");
        goTo("/");
      } else {
        console.log("Something went wrong");
        const json = await response.json();
        console.log("Error response:", json);
        console.log("Error message:", json.body.error);
        setErrorResponse(json.body.error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <form action="" className="form" onSubmit={hanleSubmit}>
        <h1>SIGNUP</h1>
        {!!errorResponse && <div className="errorMessage">{errorResponse}</div>}
        <label htmlFor="">name</label>
        <input
          type="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label htmlFor="">username</label>
        <input
          type="text"
          value={userName}
          onChange={(e) => setuserName(e.target.value)}
        />
        <label htmlFor="">contraseña</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Link to="/login">login</Link>
        <button>createuser</button>
      </form>
    </div>
  );
};

export default Signup;
