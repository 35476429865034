import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { useEffect, useState } from "react";
import { API_URL } from "../../auth/constants";
import axios from "axios";
import PieChart from "../../components/PieChart";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  //data
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/user`);
      //console.log("getUsers:", response);
      setUsers(response.data.students);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  //

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [percentStudent, setPercentStudent] = useState({});

  const getPercentStudent = async () => {
    try {
      const response = await axios.get(`${API_URL}/optionsChart`);
      setPercentStudent(response.data.student);
      //console.log("getPercentStudent:", response.data.student);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };

  const DownloadImc = async () => {
    try {
      const response = await axios.get(`${API_URL}/importdata/exportdata`, {
        responseType: "blob", // Configura axios para manejar la respuesta como blob
      });

      if (response.status !== 200)
        throw new Error("Error al descargar el archivo CSV");

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "datos.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      console.error(err);
    }
  };

  const DownloadDocentes = async () => {
    try {
      const response = await axios.get(`${API_URL}/importdata/exportdocentes`, {
        responseType: "blob", // Configura axios para manejar la respuesta como blob
      });

      if (response.status !== 200)
        throw new Error("Error al descargar el archivo CSV");

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "docentes.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      console.error(err);
    }
  };

  const DownloadInstituciones = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/importdata/exportinstituciones`,
        {
          responseType: "blob", // Configura axios para manejar la respuesta como blob
        }
      );

      if (response.status !== 200)
        throw new Error("Error al descargar el archivo CSV");

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "instituciones.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      console.error(err);
    }
  };

  const DownloadDocInst = async () => {
    try {
      const response = await axios.get(`${API_URL}/importdata/exportdocinst`, {
        responseType: "blob", // Configura axios para manejar la respuesta como blob
      });

      if (response.status !== 200)
        throw new Error("Error al descargar el archivo CSV");

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "docente-Institucion.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getPercentStudent();
    getUsers();
  }, []);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Header title="DASHBOARD" subtitle="Resumen general" />
        </Box>
        <Box display="flex" justifyContent="flex-end" ml="auto">
          <Button
            onClick={DownloadImc}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              mr: "10px", // Añade un margen derecho para espaciado
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Reporte
          </Button>
          <Button
            onClick={DownloadDocInst}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              mr: "10px", // Añade un margen derecho para espaciado
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Docente-institucion
          </Button>
          <Button
            onClick={DownloadDocentes}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              mr: "10px", // Añade un margen derecho para espaciado
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Docentes
          </Button>
          <Button
            onClick={DownloadInstituciones}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Instituciones
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={percentStudent[0]?.flaco || ""}
            subtitle="Flacos"
            progress={`${percentStudent[0]?.flaco / percentStudent[0]?.tot}`}
            increase={`${(
              (percentStudent[0]?.flaco / percentStudent[0]?.tot) *
              100
            ).toFixed(2)}%`}
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={percentStudent[0]?.normal || ""}
            subtitle="Normal"
            progress={`${percentStudent[0]?.normal / percentStudent[0]?.tot}`}
            increase={`${(
              (percentStudent[0]?.normal / percentStudent[0]?.tot) *
              100
            ).toFixed(2)}%`}
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={percentStudent[0]?.sobrepeso || ""}
            subtitle="Sobre peso"
            progress={`${
              percentStudent[0]?.sobrepeso / percentStudent[0]?.tot
            }`}
            increase={`${(
              (percentStudent[0]?.sobrepeso / percentStudent[0]?.tot) *
              100
            ).toFixed(2)}%`}
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={percentStudent[0]?.obeso || ""}
            subtitle="Obeso"
            progress={`${percentStudent[0]?.obeso / percentStudent[0]?.tot}`}
            increase={`${(
              (percentStudent[0]?.obeso / percentStudent[0]?.tot) *
              100
            ).toFixed(2)}%`}
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              ></Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                total de alumnos: {percentStudent[0]?.tot}
              </Typography>
            </Box>
            <Box>
              <IconButton></IconButton>
            </Box>
          </Box>
          <Box height="500px" m="-20px 0 0 0">
            <PieChart />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            overflow="auto"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              colors={colors.grey[100]}
              p="15px"
            >
              <Typography
                color={colors.grey[100]}
                variant="h5"
                fontWeight="600"
              >
                Usuarios
              </Typography>
            </Box>
            {users.map((transaction, i) => (
              <Box
                key={`${transaction.id_usuario}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="15px"
              >
                <Box>
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {transaction.usuario}
                  </Typography>
                  <Typography color={colors.grey[100]}>
                    {transaction.nom_usu}
                  </Typography>
                </Box>
                <Box color={colors.grey[100]}>
                  {transaction.ape1_usu} {transaction.ape2_usu}
                </Box>
                <Box
                  backgroundColor={colors.greenAccent[500]}
                  p="5px 10px"
                  borderRadius="4px"
                >
                  {transaction.TipoUsuario.nom_tipo_usuario}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <PieChart />
    </Box>
  );
};

export default Dashboard;
