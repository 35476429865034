import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, Select, TextField, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import axios from "axios";
import { API_URL } from "../../auth/constants";
import ModalAproved from "../modalprompt/ModalAproved";
import Import from "../importdata/Import";

const year = new Date().getFullYear();

const FormUsuario = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");


  //datos almacenados//

  const [nivel, setNivel] = useState([]);
  const [grado, setGrado] = useState([]);
  const [seccion, setSeccion] = useState([]);
  const [institute, setInstitute] = useState([]);
  const [zona, setZona] = useState([]);
  const [typeUser, setTypeUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);


  const getZona = async () => {
    try {
      const response = await axios.get(`${API_URL}/zona`);
      setZona(response.data.student);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }

  };

  const getNivel = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/nivel`);
      setNivel(response.data.student);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }

  };

  const getGrado = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/grado`);
      setGrado(response.data.student);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }

  };

  const getTypeUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/type-user`);
      setTypeUser(response.data.student);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const getSeccion = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/seccion`);
      setSeccion(response.data.student);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }

  };

  const handleFormSubmit = (values) => {
    //console.log("Valores del formulario:", values);
    //console.log(`${API_URL}/user`);
    setIsLoading(true);
    axios
      .post(`${API_URL}/user`, values)
      .then((response) => {
        setIsLoading(false);
        setIsRegistered(true);
      })
      .catch((error) => {
        console.error("Error al enviar los datos:", error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getNivel();
    getGrado();
    getSeccion();
    getZona();
    getTypeUsers();
  }, []);

  useEffect(() => {
    let timer;
    if (isRegistered) {
      timer = setTimeout(() => {
        setIsRegistered(false);
      }, 3000); // El modal desaparecerá después de 3 segundos
    }
    return () => clearTimeout(timer); // Limpia el timeout si el componente se desmonta o si isRegistered cambia
  }, [isRegistered]);
  
  return (
    <Box m="20px">
      <Header title="Registrar Usuario" subtitle="Crear nuevo usuario" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="nivel1">Tipo de usuario</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label="Tipo-usuario"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.typeUser} // Asegúrate de que esté usando values.typeUser aquí
                  name="typeUser" // Asegúrate de que el nombre del campo coincida con el nombre del estado
                  error={!!touched.typeUser && !!errors.typeUser}
                  helperText={touched.typeUser && errors.typeUser}
                  sx={{ gridColumn: "span 2" }}
                >

                  <MenuItem value="" disabled>
                    TipoUsuario
                  </MenuItem>
                  {typeUser.map((item) => (
                    <MenuItem key={item.id_tipo_usuario} value={item.id_tipo_usuario}>
                      {item.nom_tipo_usuario}
                    </MenuItem>
                  ))}

                </Select>
                </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Usuario"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.usuario}
                name="usuario"
                error={!!touched.usuario && !!errors.usuario}
                helperText={touched.usuario && errors.usuario}
                sx={{ gridColumn: "span 3" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contraseña"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contrasennia}
                name="contrasennia"
                error={!!touched.contrasennia && !!errors.contrasennia}
                helperText={touched.contrasennia && errors.contrasennia}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nombre Usuario"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nombre}
                name="nombre"
                error={!!touched.nombre && !!errors.nombre}
                helperText={touched.nombre && errors.nombre}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Apellido Paterno"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.apellidoPaterno}
                name="apellidoPaterno"
                error={!!touched.apellidoPaterno && !!errors.apellidoPaterno}
                helperText={touched.apellidoPaterno && errors.apellidoPaterno}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Apellido Materno"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.apellidoMaterno}
                name="apellidoMaterno"
                error={!!touched.apellidoMaterno && !!errors.apellidoMaterno}
                helperText={touched.apellidoMaterno && errors.apellidoMaterno}
                sx={{ gridColumn: "span 2" }}
              />

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Crear Nuevo Usuario
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <ModalAproved message="Cargando..." isVisible={isLoading} />
      <ModalAproved message="Usuario registrado" isVisible={isRegistered} />
      {/* <Import></Import>*/}
      <Import></Import>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  usuario: yup.string().required("El usuario es requerido"),
  nombre: yup.string().required("El nombre es requerido"),
  apellidoPaterno: yup.string().required("El apellido paterno es requerido"),
  apellidoMaterno: yup.string().required("El apellido materno es requerido"),
  typeUser: yup.string().required("La sección es requerida"),
  contrasennia: yup.string().required("La sección es requerida"),
});

const initialValues = {
  usuario: "",
  typeUser: "",
  nombre: "", 
  apellidoPaterno: "",
  apellidoMaterno: "",
  contrasennia: "",
  annio: year,
};
export default FormUsuario;