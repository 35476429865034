import React, { useEffect, useState } from "react";
import { Box, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import Header from "../../components/Header";
import axios from "axios";
import { API_URL } from "../../auth/constants";
import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../../theme";

const Pie = ({ isDashboard = false }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const options = ["ZONA", "SECCION", "NIVEL", "GRADO"];
  const [optionsChart, setOptionsChart] = useState([]);
  const [imcDetStudents, setImcDetStudents] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = async (event) => {
    //console.log("handleChange", event.target.value);
    setSelectedOption(event.target.value);
    try {
      const response = await axios.get(
        `${API_URL}/optionsChart/${event.target.value}`
      );
      setOptionsChart(response.data.student);
      //console.log("optionsChart:", response.data.student);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };

  const getImcDetStudents = async () => {
    try {
      const response = await axios.get(`${API_URL}/imc`);
      setImcDetStudents(response.data.student);

    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  useEffect(() => {
    getImcDetStudents();
  }, []);

  return (
    <Box m="20px">
      <Header title="Repotes" subtitle="Grafico de barras segun: " />
      <Box height="75vh">
        <FormControl fullWidth variant="outlined">
          <InputLabel id="select-label">Seleccion opcion</InputLabel>
          <Select
            labelId="select-label"
            value={selectedOption}
            onChange={handleChange}
            label="Seleccion opcion"
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ResponsiveBar
          data={optionsChart}
          theme={{
            // added
            axis: {
              domain: {
                line: {
                  stroke: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[100],
                },
              },
            },
            legends: {
              text: {
                fill: colors.grey[100],
              },
            },
          }}
          keys={["flaco", "normal", "sobrepeso", "obeso"]}
          indexBy="dato"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", "1.6"]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            //legend: isDashboard ? undefined : "country", // changed
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            //legend: isDashboard ? undefined : "food", // changed
            legendPosition: "middle",
            legendOffset: -40,
          }}
          enableLabel={true}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          barAriaLabel={function (e) {
            return (
              e.id + ": " + e.formattedValue + " in country: " + e.indexValue
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default Pie;
