import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  Box,
  TextField,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { API_URL } from "../../auth/constants";

const ModalDetUser = ({ isOpen, onClose, modalData, actualizarEstudiante }) => {
  const [detStudent, setDetStudent] = useState({});
  const [editing, setEditing] = useState(false);
  const [editStudent, setEditStudent] = useState({});
  const [detDoc, setDetDoc] = useState([{}]);
  const [valuesString, setValuesString] = useState("");
  const [stitutes, setInstitutes] = useState();
  const [dataEditInstitute, setDataEditInstitute] = useState();
  const getInstEst = async () => {
    try {
      const response = await axios.get(`${API_URL}/institute/instituteall`);
      //console.log("getInstEst", response.data.student);
      setInstitutes(response.data.student);
    } catch (error) {
      console.error(error);
    }
  };
  const getDetStudent = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/user/${modalData.id_usuario}`
      );
      //console.log("Datos obtenidos:", response.data.student);
      if (response.data.student.id_tipo_usuario == 1) {
        const tipo_usu = "Administrador";
        setDetStudent({
          ape1_usu: response.data.student.ape1_usu,
          ape2_usu: response.data.student.ape2_usu,
          id_tipo_usuario: tipo_usu,
          id_usuario: response.data.student.id_usuario,
          nom_usu: response.data.student.nom_usu,
          psw_usu: "",
          usuario: response.data.student.usuario,
        });
      } else {
        const tipo_usu = "Docente";
        setDetStudent({
          ape1_usu: response.data.student.ape1_usu,
          ape2_usu: response.data.student.ape2_usu,
          id_tipo_usuario: tipo_usu,
          id_usuario: response.data.student.id_usuario,
          nom_usu: response.data.student.nom_usu,
          psw_usu: "",
          usuario: response.data.student.usuario,
        });
      }
    } catch (error) {
      console.error("Error al obtener los datos de los estudiantes:", error);
    }
  };
  const getDetDoc = async () => {
    try {
      //console.log("getDetDoc", modalData);
      const response = await axios.get(
        `${API_URL}/user/detDocente/${modalData.id_usuario}`
      );
      setDetDoc(response.data.student);
      //console.log("detStudent: ", response.data.student);
    } catch (error) {
      console.error("Error al actualizar los datos de los estudiantes:", error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleCancelEdit = () => {
    setEditing(false);
  };

  const putEditStudent = async () => {
    try {
      //console.log("putEditStudent", detStudent);
      const response = await axios.put(
        `${API_URL}/user/detoneuser`,
        detStudent
      );
      setEditStudent(response.data.student);
      //console.log("detStudent: ", response.data.student);
      // setEditingIndex(-1);
      const estudianteActualizado = response.data.student;
      //console.log("antes:", estudianteActualizado);
      actualizarEstudiante(0, estudianteActualizado);
      //console.log("despues:");
    } catch (error) {
      console.error("Error al actualizar los datos de los estudiantes:", error);
    }
  };

  const handleChange = (field, value) => {
    setDetStudent((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (isOpen) {
      getDetDoc();
      getDetStudent();
      getInstEst();
    }
  }, [isOpen]);

  useEffect(() => {
    //console.log("Estado detStudent actualizado:", detStudent);
  }, [detStudent]);

  const eliminarInst = async () => {
    try {
      const response = await axios.delete(
        `${API_URL}/detdocente/${modalData.id_usuario}/${dataEditInstitute.id_inst_est}`
      );

      // Eliminar el elemento correspondiente de detDoc
      setDetDoc(prevState => {
        // Filtrar detDoc para excluir el elemento eliminado
        const updatedDetDoc = prevState.filter(item => !(item.id_usuario === modalData.id_usuario && item.id_inst_est === dataEditInstitute.id_inst_est));
        return updatedDetDoc;
      });
    } catch (error) {
      console.error("Error al eliminar institución:", error);
    }
  };
  const AgregarInst = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/detdocente/${modalData.id_usuario}`,
        dataEditInstitute
      );
      //console.log("asdasd",response.data.student,"..",detDoc)
      // Asegúrate de que response.data.student sea un objeto válido con las propiedades necesarias
      const newInstitute = {
        id_det_doc: response.data.student.id_det_doc,
        id_inst_est: response.data.student.id_inst_est,
        id_usuario: response.data.student.id_usuario,
        nom_inst_est: response.data.student.InstituteStudent.nom_inst_est,
      };

      setDetDoc((prevState) => [...prevState, newInstitute]);
    } catch (error) {
      console.error("Error al agregar institución:", error);
    }
  };
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        Título del Modal
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h3">ARCHIVO DEL ESTUDIANTE</Typography>
        {modalData && (
          <div
            style={{
              margin: "16px 0",
              padding: "16px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            <Typography>
              Nombre y apellidos: {modalData.nom_usu} {modalData.ape1_usu}{" "}
              {modalData.ape2_usu}
            </Typography>
            <Typography>Usuario: {modalData.usuario}</Typography>
          </div>
        )}
        <Typography variant="h6">Detalles del usuario</Typography>
        <Grid container>
          {Object.keys(detStudent).length > 0 && (
            <Grid item xs={12}>
              <div
                style={{
                  margin: "16px 0",
                  padding: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography>Nombres: </Typography>
                    <TextField
                      name="nom_usu"
                      onChange={(e) => handleChange("nom_usu", e.target.value)}
                      disabled={!editing}
                      value={detStudent.nom_usu || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Apellido Paterno: </Typography>
                    <TextField
                      name="ape1_usu"
                      onChange={(e) => handleChange("ape1_usu", e.target.value)}
                      disabled={!editing}
                      value={detStudent.ape1_usu || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Apellido Materno: </Typography>
                    <TextField
                      name="ape2_usu"
                      onChange={(e) => handleChange("ape2_usu", e.target.value)}
                      disabled={!editing}
                      value={detStudent.ape2_usu || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Contraseña: </Typography>
                    <TextField
                      name="psw_usu"
                      onChange={(e) => handleChange("psw_usu", e.target.value)}
                      disabled={!editing}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Tipo de usuario: </Typography>
                    <br />
                    <FormControl fullWidth>
                      <InputLabel id="id_tipo_usuario">
                        Tipo de usuario:
                      </InputLabel>
                      <Select
                        labelId="id_tipo_usuario"
                        label="id_tipo_usuario"
                        value={detStudent.id_tipo_usuario}
                        onChange={(e) =>
                          handleChange("id_tipo_usuario", e.target.value)
                        }
                        disabled={!editing}
                      >
                        <MenuItem value={detStudent.id_tipo_usuario} disabled>
                          {detStudent.id_tipo_usuario}
                        </MenuItem>
                        <MenuItem value="Administrador">Administrador</MenuItem>
                        <MenuItem value="Docente">Docente</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Tipo de usuario: </Typography>
                    <br />
                    <Autocomplete
                      options={stitutes}
                      getOptionLabel={(option) => option.nom_inst_est}
                      onChange={(event, selectedOption) => {
                        if (selectedOption) {
                          setDataEditInstitute({ id_inst_est: selectedOption.id_inst_est });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Institución" variant="outlined" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup>
                      {detDoc.map((option) => (
                        <p key={option.id_det_doc}>{option.nom_inst_est}</p>
                      ))}
                    </FormGroup>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          marginRight: "10px",
                          marginLeft: "50px",
                          marginTop: "10px",
                        }}
                        onClick={AgregarInst}
                      >
                        agregar
                      </Button>

                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ marginTop: "10px" }}
                        onClick={eliminarInst}
                      >
                        eliminar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  {!editing ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEditClick}
                    >
                      Editar
                    </Button>
                  ) : (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={putEditStudent}
                      >
                        Guardar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCancelEdit}
                      >
                        Cancelar
                      </Button>
                    </React.Fragment>
                  )}
                </Box>
              </div>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalDetUser;
