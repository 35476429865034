import { Autocomplete, Box, InputLabel } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme, Button } from "@mui/material";
import axios from "axios";
import { API_URL } from "../../auth/constants";
import React, { useEffect, useState } from "react";
import ModalDetStudent from "../modals/ModalDetStudent";
import {
  TextField,
  MenuItem,
  Select,
  Grid,
  Typography,
  FormControl,
} from "@mui/material";
import { useAuth } from "../../auth/AuthProvider";

const Contacts = () => {
  const auth = useAuth();
  //
  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token"); // Obtener el token del almacenamiento local
    if (token) {
      config.headers.Authorization = `${token}`; // Agregar el token al encabezado de autorización
    }
    return config;
  });
  //
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [estudiantes, setEstudiantes] = useState([]);
  const [pesotalla, setPesotalla] = useState({});
  const [editedTalla, setEditedTalla] = useState({});
  const [editedPeso, setEditedPeso] = useState({});
  const [successMessage, setSuccessMessage] = useState("");


  const actualizarEstudiante = (nuevoEstudiante) => {
    //console.log("quellega:", "--", nuevoEstudiante.id_det_est);
    //console.log("actualizarEstudiante1",estudiantes)

    const nuevosEstudiantes = estudiantes.map(item => {
      // Comparar el id_usuario de cada item con el id_usuario del nuevoEstudiante
      if (item.id_det_est === nuevoEstudiante.id_det_est) {
        // Si son iguales, retornar el nuevoEstudiante para reemplazar el antiguo
        //console.log("entro",item.id_det_est)
        return {
          ...item,
          ...nuevoEstudiante
        };
      }
      // Si no son iguales, retornar el item sin cambios
      return item;
    });


    // Actualizar el estado estudiantes
    setEstudiantes(nuevosEstudiantes);
    //console.log("actualizarEstudiante2",estudiantes)
    //console.log("estudiantes actualizados:::", estudiantes);
    //console.log("estudiantes estado:::", nuevosEstudiantes);
  };


  const [nivel, setNivel] = useState([]);
  const [grado, setGrado] = useState([]);
  const [seccion, setSeccion] = useState([]);
  const [institute, setInstitute] = useState([]);
  const [zona, setZona] = useState([]);

  const getZona = async () => {

    try {
      const response = await axios.get(`${API_URL}/zona`);
      setZona(response.data.student);
      //console.log(zona, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const getInstitute = async () => {
    try {
      const response = await axios.get(`${API_URL}/institute/instituteall`);
      setInstitute(response.data.student);
      //console.log(institute, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const getNivel = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/nivel`);
      setNivel(response.data.student);
      //console.log(nivel, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const getGrado = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/grado`);
      setGrado(response.data.student);
      //console.log(grado, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const getSeccion = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/seccion`);
      setSeccion(response.data.student);
      //console.log("seccion:", seccion, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const handleSave = async (data) => {
    setEditedTalla((prev) => ({ ...prev, [data.id_est]: data.talla }));
    setEditedPeso((prev) => ({ ...prev, [data.id_est]: data.peso }));
    //console.log("handleSave", data);
    try {
      const response = await axios.put(`${API_URL}/estudiante`, data);

      //console.log("Estudiante editadopesotalla:", response);

      // Mostrar mensaje de éxito
      setSuccessMessage("¡Guardado exitosamente!");

      // Limpiar el mensaje después de 3 segundos
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error al eliminar estudiante:", error);
    }
  };
  const handleEdit = (data, params) => {
    //console.log("handleEdit", data, "--", params);
    setModalData(data);
    //console.log("modalData", modalData);
    setIsModalOpen(true);
  };

  const handleDelete = async (data) => {
    //console.log("handleDelete", data);
    try {
      await axios.delete(`${API_URL}/estudiante/${data.id_est}`);
      // Eliminar la fila de estudiantes en el estado
      setEstudiantes((prevEstudiantes) =>
        prevEstudiantes.filter(
          (estudiante) => estudiante.id_est !== data.id_est
        )
      );
      //console.log("Estudiante eliminado:", data.id_est);
    } catch (error) {
      console.error("Error al eliminar estudiante:", error);
    }
  };

  //modal

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const openModal = (data) => {
    setModalData(data);
    //console.log("openModal", modalData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const fetchEstudiantes = async (nivel1, grado1, seccion1, institucion1) => {
    try {
      if (!nivel1 || !grado1 || !seccion1 || !institucion1) {
        // Si algún campo está vacío, muestra un mensaje de error al usuario
        alert("Por favor complete todos los casilleros");
        return;
      }

      //console.log("fetchEstudiantes", nivel1, grado1, seccion1, institucion1);
      const response = await axios.get(
        `${API_URL}/estudiante/${nivel1}/${grado1}/${seccion1}/${institucion1}/${auth.id}`
      );
      setEstudiantes(response.data.student);

    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  //
  useEffect(() => {
    // Llamada a la API para obtener la data de estudiantes
    getZona();
    getNivel();
    getSeccion();
    getGrado();
    getInstitute();
  }, []);

  const columns = [
    { field: "dni_est", headerName: "DNI", flex: 0.5 },
    { field: "nom_est", headerName: "Nombre", flex: 1 },
    {
      field: "apellidos",
      headerName: "Apellidos",
      flex: 1,
      valueGetter: (params) => `${params.row.ape1_est || ''} ${params.row.ape2_est || ''}`
    },
    { field: "nom_seccion_est", headerName: "Seccion", flex: 1 },
    {
      field: "talla_det_est",
      headerName: "Talla",
      flex: 1,
      editable: true,
      valueGetter: (params) =>
        editedTalla[params.row.id_est] !== undefined
          ? editedTalla[params.row.id_est]
          : params.row.talla_det_est,
      renderCell: (params) => (
        <TextField
          type="text"
          value={params.row.talla_det_est}
          onChange={(e) =>
            setEditedTalla({
              ...editedTalla,
              [params.row.id_est]: e.target.value,
            })
          }
          style={{
            color: params.row.talla_det_est ? "green" : "", // Si talla_det_est no es nulo, se pinta de verde
          }}
        />
      ),
    },
    {
      field: "peso_det_est",
      headerName: "Peso",
      flex: 1,
      editable: true,
      valueGetter: (params) =>
        editedPeso[params.row.id_est] !== undefined
          ? editedPeso[params.row.id_est]
          : params.row.peso_det_est,
      renderCell: (params) => (
        <TextField
          type="text"
          value={params.row.peso_det_est}
          onChange={(e) =>
            setEditedPeso({
              ...editedPeso,
              [params.row.id_est]: e.target.value,
            })
          }
          style={{
            color: params.row.peso_det_est ? "green" : "", // Si peso_det_est no es nulo, se pinta de verde
          }}
        />
      ),
    },
    {
      field: "opciones",
      headerName: "Opciones",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSave(params.row);
              //openModal(params.row); // Pasar los datos del estudiante al modal
            }}
          >
            Guardar

          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleEdit(params.row, params);
              //openModal(params.row); // Pasar los datos del estudiante al modal
            }}
          >
            Editar
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row)}
          >
            Eliminar
          </Button>
        </Box>
      ),
    },
  ];

  const [nivel1, setNivel1] = useState("");
  const [grado1, setGrado1] = useState("");
  const [seccion1, setSeccion1] = useState("");
  const [institucion1, setInstitucion1] = useState("");

  const handleSave1 = () => {
    // Aquí puedes realizar la lógica para guardar los valores seleccionados
    //console.log("Nivel:", nivel1);
    //console.log("Grado:", grado1);
    //console.log("Sección:", seccion1);
    //console.log("Institucion", institucion1);
    fetchEstudiantes(nivel1, grado1, seccion1, institucion1);
  };
  return (
    <>
      <Box m="20px">
        <Header
          title="Informacion de Estudiantes"
          subtitle="Ingrese Grado Seccion y Nivel para visualizar a los estudiantes"
        />
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="nivel1">Nivel</InputLabel>
                <Select
                  labelId="nivel1"
                  label="Nivel"
                  value={nivel1}
                  onChange={(e) => setNivel1(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    NIVEL
                  </MenuItem>
                  <MenuItem value="1">PRIMARIA</MenuItem>
                  <MenuItem value="2">SECUNDARIA</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="grado">Grado</InputLabel>
                <Select
                  labelId="grado"
                  label="Grado"
                  value={grado1}
                  onChange={(e) => setGrado1(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    GRADO
                  </MenuItem>
                  <MenuItem value="1">PRIMERO</MenuItem>
                  <MenuItem value="2">SEGUNDO</MenuItem>
                  <MenuItem value="3">TERCERO</MenuItem>
                  <MenuItem value="4">CUARTO</MenuItem>
                  <MenuItem value="5">QUINTO</MenuItem>
                  <MenuItem value="6">SEXTO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="seccion">Seccion</InputLabel>
                <Select
                  labelId="seccion"
                  label="Sección"
                  value={seccion1}
                  onChange={(e) => setSeccion1(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    SECCION
                  </MenuItem>
                  {seccion.map((item) => (
                    <MenuItem key={item.id_seccion_est} value={item.id_seccion_est}>
                      {item.nom_seccion_est}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                options={institute} // Asegúrate de que el array se llame "institutes" o el nombre que estás usando
                getOptionLabel={(option) => option.nom_inst_est}
                onChange={(event, selectedOption) => {
                  if (selectedOption) {
                    setInstitucion1(selectedOption.id_inst_est);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Institución" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                onClick={handleSave1}
                variant="contained"
                color="secondary"
                style={{ height: "50px" }}
              >
                Seleccionar

              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={estudiantes}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            getRowId={(row) => row.id_est}
          />
        </Box>
      </Box>
      {successMessage && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            padding: "10px",
            background: "#4caf50",
            color: "#fff",
            zIndex: 9999,
          }}
        >
          {successMessage}
        </Box>
      )}
      <ModalDetStudent
        isOpen={isModalOpen}
        onClose={closeModal}
        modalData={modalData}
        estudiantes={estudiantes} // Pasa el estado de estudiantes como prop
        setEstudiantes={setEstudiantes} // Pasa la función para actualizar estudiantes como prop
        actualizarEstudiante={actualizarEstudiante} // Pasa la función para actualizar un estudiante específico como prop
      >
        <button onClick={closeModal}>Close Modal</button>
      </ModalDetStudent>
    </>
  );
};

export default Contacts;
