import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../auth/constants";

const LineChart = ({ isCustomLineColors = false, isDashboard = false }) => {



 const datac = [
    {
      id: "Flaco",
      color: tokens("dark").greenAccent[500],
      data: [
        
      ],
    },
    {
      id: "france",
      color: tokens("dark").blueAccent[300],
      data: [
        {
          x: "plane",
          y: 212,
        },
        {
          x: "helicopter",
          y: 190,
        },
        {
          x: "boat",
          y: 270,
        },
        {
          x: "train",
          y: 9,
        },
        {
          x: "subway",
          y: 75,
        },
        {
          x: "bus",
          y: 175,
        },
        {
          x: "car",
          y: 33,
        },
        {
          x: "moto",
          y: 189,
        },
        {
          x: "bicycle",
          y: 97,
        },
        {
          x: "horse",
          y: 87,
        },
        {
          x: "skateboard",
          y: 299,
        },
        {
          x: "others",
          y: 251,
        },
      ],
    },
    {
      id: "us",
      color: tokens("dark").redAccent[200],
      data: [
        {
          x: "plane",
          y: 191,
        },
        {
          x: "helicopter",
          y: 136,
        },
        {
          x: "boat",
          y: 91,
        },
        {
          x: "train",
          y: 190,
        },
        {
          x: "subway",
          y: 211,
        },
        {
          x: "bus",
          y: 152,
        },
        {
          x: "car",
          y: 189,
        },
        {
          x: "moto",
          y: 152,
        },
        {
          x: "bicycle",
          y: 8,
        },
        {
          x: "horse",
          y: 197,
        },
        {
          x: "skateboard",
          y: 107,
        },
        {
          x: "others",
          y: 170,
        },
      ],
    },
  ];
  

  const [percentStudent, setPercentStudent] = useState({});

  const getPercentStudent = async () => {
    try {
      const response = await axios.get(`${API_URL}/optionsChart`);
      setPercentStudent(response.data.student);
      console.log("enen:", response.data.student);
      console.log("data:",data)
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  useEffect(() => {
    getPercentStudent();
  }, []);
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ResponsiveLine
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="catmullRom"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "transportation", // added
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickValues: 5, // added
        tickSize: 3,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "count", // added
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default LineChart;