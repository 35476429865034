import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  Box,
  TextField,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { API_URL } from "../../auth/constants";
import axios from "axios";
import SelectWithSearch from "../../components/SelectWithSearch";

const ModalDetStudent = ({ isOpen, onClose, modalData, estudiantes, setEstudiantes, actualizarEstudiante }) => {
  const [level, setLevel] = useState({});
  const [section, setSection] = useState({});
  const [gradee, setGrade] = useState({});
  const [institute, setInstitute] = useState({});
  const [zone, setZone] = useState({});
  const [annio, setAnnio] = useState({});

  //

  const options = Object.keys(zone).map((key) => ({
    value: key,
    label: zone[key].nom_zona_est,
  }));

  const optionsGrade = Object.keys(gradee).map((key) => ({
    value: key,
    label: gradee[key].nom_grad_est,
  }));

  const optionsLevel = Object.keys(level).map((key) => ({
    value: key,
    label: level[key].nom_nivel_est,
  }));

  const optionsSection = Object.keys(section).map((key) => ({
    value: key,
    label: section[key].nom_seccion_est,
  }));

  const optionsInstitute = Object.keys(institute).map((key) => ({
    value: key,
    label: institute[key].nom_inst_est,
  }));
  //

  const [editingIndex, setEditingIndex] = useState(-1);
  const [detStudent, setDetStudent] = useState({
    id_det_est: "",
    Edad: "",
    institucion_Est: "",
    talla_det_est: "",
    peso_det_est: "",
    nom_grad_est: "",
    nom_nivel_est: "",
    nom_seccion_est: "",
  });
  const [editStudent, setEditStudent] = useState({
    Annio: "",
    institucion_Est: "",
    talla_det_est: "",
    peso_det_est: "",
    nom_grad_est: "",
    nom_nivel_est: "",
    seccion: "",
    id_det_est: "",
  });

  const getAnnio = async () => {
    try {
      const response = await axios.get(`${API_URL}/annio`);
      setAnnio(response.data.student);
      //console.log("annio:", annio);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const getZona = async () => {
    try {
      const response = await axios.get(`${API_URL}/zona`);
      setZone(response.data.student);
      //console.log("zone:", zone);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const getInstitute = async () => {
    try {
      const response = await axios.get(`${API_URL}/institute/instituteall`);
      setInstitute(response.data.student);
      //console.log(institute, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };

  const getNivel = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/nivel`);
      setLevel(response.data.student);
      //console.log(level, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };

  const getGrado = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/grado`);
      setGrade(response.data.student);
      //console.log(gradee, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const getSeccion = async () => {
    try {
      const response = await axios.get(`${API_URL}/detStudent/seccion`);
      setSection(response.data.student);
      //console.log("seccion:", section, "---", response.data);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  const token = localStorage.getItem("token");
  const userId = token ? JSON.parse(token)?.user?.id : "";
  const [showAddDetailForm, setShowAddDetailForm] = useState(false); // Estado para mostrar/ocultar el formulario para agregar un nuevo detalle
  const [newDetail, setNewDetail] = useState({
    // Estado para almacenar los valores del nuevo detalle
    id_est: "",
    idUsu: userId,
    Annio: "",
    institucion_Est: "",
    talla_det_est: "",
    peso_det_est: "",
    nom_grad_est: "",
    nom_nivel_est: "",
    seccion: "",
    zonaStudent: "",
  });

  //
  const modaldata1 = () => {
    //console.log("modalesdata: ", modalData);
  };

  const getDetStudent = async () => {
    //console.log("getDetStudent: ", modalData.id_est);
    try {
      const response = await axios.get(
        `${API_URL}/detStudent/${modalData.id_est}`
      );
      setDetStudent(response.data.student);
      console.log("getDetStudent: ", response.data.student);
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleAddDetailClick = () => {
    setShowAddDetailForm(true);
  };

  const handleCancelAddDetail = () => {
    setShowAddDetailForm(false);
  };

  const handleEditClick = (index) => {
    setEditingIndex(index); // Establece el índice del estudiante en modo de edición
  };

  const handleCancelEdit = () => {
    setEditingIndex(-1); // Desactiva el modo de edición al hacer clic en Cancelar
  };
  const putEditStudent = async () => {
    try {
      //setEditStudent(editStudent.id_det_est=item_idDetStudent);
      //console.log("postEditStudent: ", detStudent, "--");
      const response = await axios.put(`${API_URL}/detStudent`, detStudent);
      setEditStudent(response.data.student);
      //console.log("detStudent: ", response.data.student);
      //setEditingIndex(-1);
      const estudianteActualizado = response.data.student;
      //console.log("antes:",estudianteActualizado)
      actualizarEstudiante( estudianteActualizado);
      //console.log("despues:")

    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };

  const handleSaveNewDetail = async (item_id_est) => {
    //console.log("handleSaveNewDetail", newDetail, "-", item_id_est, "--");
    const response = await axios.post(
      `${API_URL}/detStudent/${item_id_est}`,
      newDetail
    );

    // Aquí puedes enviar los datos del nuevo detalle al servidor si es necesario
    // Por simplicidad, solo los agregaremos a la lista localmente
    setDetStudent([...detStudent, newDetail]);
    // Reiniciar el estado del nuevo detalle y ocultar el formulario
    setNewDetail({
      id_est: "",
      idUsu: userId,
      Annio: "",
      institucion_Est: "",
      talla_det_est: "",
      peso_det_est: "",
      SeccionEstudiante: "",
      NivelEstudiante: "",
      GradoEstudiante: "",
      zonaStudent: "",
    });
    setShowAddDetailForm(false);
  };
  const handleChange = (index, field, value) => {
    console.log("handleChange", index, field, value);

    setDetStudent((prevStudents) => {
      const updatedStudents = [...prevStudents];
      updatedStudents[0] = {
        ...updatedStudents[0],
        [field]: value,
      };
      return updatedStudents;
    });
  };
  

  const handleDelete = async (item_det_est) => {
    //console.log("item_det_est", item_det_est);
    try {
      const response = await axios.delete(
        `${API_URL}/detStudent/${item_det_est}`
      );
      //console.log("handleDelete: ", response);
      // Llama a la función onDeleteDetail para indicar que se ha eliminado un detalle
      setDetStudent((prevDetStudent) =>
      prevDetStudent.filter(
          (detStudent) => detStudent.id_det_est !== detStudent.id_det_est
        )
      );
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getDetStudent();
      getGrado();
      getInstitute();
      getZona();
      getNivel();
      getSeccion();
      getAnnio();
    }
    modaldata1();
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        Modal Title
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey.500",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h3">ARCHIVO DEL ESTUDIANTE</Typography>
        {modalData && (
          <div
            style={{
              margin: "16px 0",
              padding: "16px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            <Typography>
              Nombre y apellidos: {modalData.nom_est} {modalData.ape1_est}{" "}
              {modalData.ape2_est}
            </Typography>
            <Typography>DNI: {modalData.dni_est}</Typography>
            <Typography>Edad: {modalData.edad}</Typography>
          </div>
        )}
        <Typography variant="h6">Parte historial del alumno</Typography>
        <Grid container>
          {detStudent.length > 0 &&
            detStudent.map((student, index) => (
              <Grid item xs={12} key={index}>
                <div
                  style={{
                    margin: "16px 0",
                    padding: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography>Edad: </Typography>
                      <TextField
                        name="Annio"
                        onChange={(e) =>
                          handleChange(student.id_det_est, "Edad", e.target.value)
                        }
                        disabled={editingIndex !== index}
                        value={student.Edad}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Institución: </Typography>

                      <Autocomplete
                        name="institucion_Est"
                        options={optionsInstitute}
                        onChange={(event, selectedOption) => {
                          const selectedValue = selectedOption
                            ? selectedOption.label
                            : "";
                          setDetStudent((prevStudent) => {
                            const updatedStudent = [...prevStudent];
                            updatedStudent[index].InstituteStudent =
                              selectedValue; // Asignar el valor seleccionado al estado
                            return updatedStudent;
                          });
                        }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={student.InstituteStudent}
                            variant="outlined"
                          />
                        )}
                        disabled={editingIndex !== index}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Talla: </Typography>
                      <TextField
                        name="talla_det_est"
                        onChange={(e) =>
                          handleChange(student.id_det_est, "talla_det_est", e.target.value)
                        }
                        disabled={editingIndex !== index}
                        value={student.talla_det_est}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Peso: </Typography>
                      <TextField
                        name="peso_det_est"
                        variant="outlined"
                        onChange={(e) =>
                          handleChange(student.id_det_est, "peso_det_est", e.target.value)
                        }
                        disabled={editingIndex !== index}
                        value={student.peso_det_est}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Sección: </Typography>

                      <Autocomplete
                        name="SeccionEstudiante"
                        options={optionsSection}
                        onChange={(event, selectedOption) => {
                          const selectedValue = selectedOption
                            ? selectedOption.label
                            : "";
                          setDetStudent((prevStudent) => {
                            const updatedStudent = [...prevStudent];
                            updatedStudent[index].SeccionEstudiante =
                              selectedValue; // Asignar el valor seleccionado al estado
                            return updatedStudent;
                          });
                        }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={student.SeccionEstudiante}
                            variant="outlined"
                          />
                        )}
                        disabled={editingIndex !== index}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Nivel: </Typography>
                      <Autocomplete
                        name="NivelEstudiante"
                        options={optionsLevel}
                        onChange={(event, selectedOption) => {
                          const selectedValue = selectedOption
                            ? selectedOption.label
                            : "";
                          setDetStudent((prevStudent) => {
                            const updatedStudent = [...prevStudent];
                            updatedStudent[index].NivelEstudiante =
                              selectedValue; // Asignar el valor seleccionado al estado
                            return updatedStudent;
                          });
                        }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={student.NivelEstudiante}
                            variant="outlined"
                          />
                        )}
                        disabled={editingIndex !== index}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Grado:</Typography>

                      <Autocomplete
                        name="GradoEstudiante"
                        options={optionsGrade}
                        onChange={(event, selectedOption) => {
                          const selectedValue = selectedOption
                            ? selectedOption.label
                            : "";
                          setDetStudent((prevStudent) => {
                            const updatedStudent = [...prevStudent];
                            updatedStudent[index].GradoEstudiante =
                              selectedValue; // Asignar el valor seleccionado al estado
                            return updatedStudent;
                          });
                        }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={student.GradoEstudiante}
                            variant="outlined"
                          />
                        )}
                        disabled={editingIndex !== index}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Zona:</Typography>
                      <Autocomplete
                        name="zona"
                        options={options}
                        onChange={(event, selectedOption) => {
                          const selectedValue = selectedOption
                            ? selectedOption.label
                            : "";
                          setDetStudent((prevStudent) => {
                            const updatedStudent = [...prevStudent];
                            updatedStudent[index].zonaStudent = selectedValue; // Asignar el valor seleccionado al estado
                            return updatedStudent;
                          });
                        }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={student.zonaStudent}
                            variant="outlined"
                          />
                        )}
                        disabled={editingIndex !== index}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    {editingIndex !== index ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEditClick(index)}
                      >
                        Editar
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => putEditStudent()}
                        >
                          Guardar
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleCancelEdit}
                        >
                          Cancelar
                        </Button>
                      </React.Fragment>
                    )}
                  </Box>
                </div>
              </Grid>
            ))}
        </Grid>
        {showAddDetailForm && (
          <div
            style={{
              margin: "16px 0",
              padding: "16px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            <Select
              label="Año"
              value={newDetail.Annio}
              onChange={(e) =>
                setNewDetail({ ...newDetail, Annio: e.target.value })
              }
              fullWidth
              margin="normal"
            >
              <MenuItem value="" disabled>
                Año
              </MenuItem>
              {annio.map((item) => (
                <MenuItem key={item.id_annio} value={item.id_annio}>
                  {item.nom_annio}
                </MenuItem>
              ))}
            </Select>
            <Select
              label="Institución"
              value={newDetail.institucion_Est}
              onChange={(e) =>
                setNewDetail({ ...newDetail, institucion_Est: e.target.value })
              }
              fullWidth
              margin="normal"
            >
              <MenuItem value="" disabled>
                Institucion
              </MenuItem>
              {institute.map((item) => (
                <MenuItem key={item.id_inst_est} value={item.id_inst_est}>
                  {item.nom_inst_est}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Talla"
              value={newDetail.talla_det_est}
              onChange={(e) =>
                setNewDetail({ ...newDetail, talla_det_est: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Peso"
              value={newDetail.peso_det_est}
              onChange={(e) =>
                setNewDetail({ ...newDetail, peso_det_est: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <Select
              label="Seccion"
              value={newDetail.SeccionEstudiante}
              onChange={(e) =>
                setNewDetail({
                  ...newDetail,
                  SeccionEstudiante: e.target.value,
                })
              }
              fullWidth
              margin="normal"
            >
              <MenuItem value="" disabled>
                Seccion
              </MenuItem>
              {section.map((item) => (
                <MenuItem key={item.id_seccion_est} value={item.id_seccion_est}>
                  {item.nom_seccion_est}
                </MenuItem>
              ))}
            </Select>
            <Select
              label="Nivel"
              value={newDetail.NivelEstudiante}
              onChange={(e) =>
                setNewDetail({ ...newDetail, NivelEstudiante: e.target.value })
              }
              fullWidth
              margin="normal"
            >
              <MenuItem value="" disabled>
                Nivel
              </MenuItem>
              {level.map((item) => (
                <MenuItem key={item.id_nivel_est} value={item.id_nivel_est}>
                  {item.nom_nivel_est}
                </MenuItem>
              ))}
            </Select>
            <Select
              label="Grado"
              value={newDetail.GradoEstudiante}
              onChange={(e) =>
                setNewDetail({ ...newDetail, GradoEstudiante: e.target.value })
              }
              fullWidth
              margin="normal"
            >
              <MenuItem value="" disabled>
                Nivel
              </MenuItem>
              {gradee.map((item) => (
                <MenuItem key={item.id_grad_est} value={item.id_grad_est}>
                  {item.nom_grad_est}
                </MenuItem>
              ))}
            </Select>
            <Select
              label="Zona"
              value={newDetail.zonaStudent}
              onChange={(e) =>
                setNewDetail({ ...newDetail, zonaStudent: e.target.value })
              }
              fullWidth
              margin="normal"
            >
              <MenuItem value="" disabled>
                Zona
              </MenuItem>
              {zone.map((item) => (
                <MenuItem key={item.id_zona_est} value={item.id_zona_est}>
                  {item.nom_zona_est}
                </MenuItem>
              ))}
            </Select>

            {/* Agrega más campos de entrada para otros detalles según sea necesario */}
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSaveNewDetail(modalData.id_est)}
              >
                Guardar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancelAddDetail}
              >
                Cancelar
              </Button>
            </Box>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalDetStudent;
