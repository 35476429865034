import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
//import { mockPieData as data  } from "../data/mockData";
import axios from "axios";
import { API_URL } from "../auth/constants";

const PieChart = () => {
  const [percentStudent, setPercentStudent] = useState({});

  const getPercentStudent = async () => {
    try {
      const response = await axios.get(`${API_URL}/optionsChart`);
      setPercentStudent(response.data.student);
      //console.log("piechart2:", response.data.student);
      //console.log("ASD",(Number(response.data.student[0].flaco)+Number(response.data.student[0].normal)+Number(response.data.student[0].obeso)+Number(response.data.student[0].sobrepeso)))
    } catch (error) {
      console.error("Error fetching estudiantes data:", error);
    }
  };
  
  const data = percentStudent.length > 0 ? [
    {
      id: "flaco",
      label: "flaco",
      value: percentStudent[0].flaco,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "normal",
      label: "normal",
      value: percentStudent[0].normal,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "obeso",
      label: "obeso",
      value: percentStudent[0].obeso,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "sobrepeso",
      label: "sobrepeso",
      value: percentStudent[0].sobrepeso,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "faltantes",
      label: "faltantes",
      value: ((percentStudent[0].tot)-(Number(percentStudent[0].flaco)+Number(percentStudent[0].normal)+Number(percentStudent[0].obeso)+Number(percentStudent[0].sobrepeso))),
      color: "hsl(229, 70%, 50%)",
    },
  ] : [];


  useEffect(() => {
    getPercentStudent();
  }, []);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <ResponsivePie
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[900],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={colors.grey[100]}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
     // enableArcLabels={false}
      arcLabelsRadiusOffset={0.4}
      arcLabelsSkipAngle={7}
      
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 3]],
      }}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "black",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default PieChart;